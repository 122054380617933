<template>
  <!-- 礼物管理页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 礼物状态 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="礼物状态">
                    <a-select v-model="queryParam.delFlag" allow-clear placeholder="请选择">
                      <a-select-option v-for="(item, index) in giftStatusList" :key="index" :value="item.status">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 申请人姓名 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="礼物名称">
                    <a-input v-model="queryParam.name" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                </a-col>
                <a-col :md="24" :sm="24" style="margin-bottom: 20px">
                  <a-button style="border-radius: 2px" type="primary" @click="$refs.addForm.add()">添加礼物</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <!-- logo插槽 -->
            <span slot="logo" slot-scope="text, record" @click="clickSwitch(record)">
              <img :src="text" alt="" />
            </span>
            <!-- 礼物状态插槽 -->
            <span slot="delFlag" slot-scope="text, record" @click="clickSwitch(record)">
              <a-switch
                checked-children="启用"
                un-checked-children="禁用"
                v-model="record.checked"
                @change="changeSwitch"
              />
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.editForm.edit(record)">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm lacement="topRight" title="确认删除？" @confirm="() => giftDelete(record)">
                <a>删除</a>
              </a-popconfirm>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <add-form
      ref="addForm"
      @ok="
        () => {
          this.initTableData()
        }
      "
    ></add-form>
    <edit-form
      ref="editForm"
      @ok="
        () => {
          this.getList()
        }
      "
    ></edit-form>
  </div>
</template>

<script>
import { getGiftList, giftDelete } from '@/api/modular/mallLiving/check/gift'

import addForm from './addForm.vue'
import editForm from './editForm.vue'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '礼物名称',
    align: 'center',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'logo',
    dataIndex: 'logo',
    key: 'logo',
    align: 'center',
    scopedSlots: { customRender: 'logo' },
  },
  {
    title: '产币',
    dataIndex: 'coin',
    key: 'coin',
    align: 'center',
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
  },
  {
    title: '礼物状态',
    dataIndex: 'delFlag',
    key: 'delFlag',
    align: 'center',
    scopedSlots: { customRender: 'delFlag' },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'center',
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    fixed: 'right',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      recordItem: null,
      loading: false,
      queryParam: {},
      giftStatusList: [
        { status: 1, name: '启用' },
        { status: 3, name: '禁用' },
      ],
    }
  },
  components: {
    addForm,
    editForm,
    // oneForm
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    clickSwitch(record) {
      this.recordItem = record
    },

    changeSwitch(e) {
      let delFlag = e ? 1 : 3
      setTimeout(() => {
        this.changeTypeStatus(delFlag)
      }, 300)
    },
    giftDelete(record) {
      this.recordItem = record
      this.changeTypeStatus(2)
    },
    //切换礼物状态函数（启用/禁用/删除）
    async changeTypeStatus(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await giftDelete(params)
      var text = delFlag == 1 ? '启用' : delFlag == 2 ? '删除' : '禁用'
      if (data.code == 200) {
        this.$message.success('礼物 ' + this.recordItem.name + ' 已' + text)
        this.getList()
      }
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      getGiftList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 40px;
  height: 40px;
}
</style>
