<template>
  <a-modal
    title="添加礼物"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 礼物名称 -->
            <a-form-item label="礼物名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                placeholder="请输入"
                v-decorator="['name', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <!-- 产币 -->
            <a-form-item label="产币" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number
                placeholder="请输入"
                :min="1"
                :precision="0"
                :max="10000000"
                style="width: 40%; min-width: 100px"
                v-decorator="['coin', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <!-- 排序 -->
            <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number
                placeholder="请输入"
                :min="1"
                :precision="0"
                :max="10000000"
                style="width: 40%; min-width: 100px"
                v-decorator="['sort', { rules: [{ required: true, message: '请输入！' }], initialValue: 10 }]"
              />
            </a-form-item>
            <!-- 礼物logo -->
            <a-form-item has-feedback label="logo" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <div style="width: 104px; height: 104px">
                <a-upload
                  style="margin: auto"
                  name="avatar"
                  accept=".png,.jpg,jpeg,.gif"
                  list-type="picture-card"
                  :show-upload-list="false"
                  :customRequest="uploadImg"
                  v-decorator="['picture', { rules: [{ required: true, message: '请上传！' }] }]"
                >
                  <img :src="picture" v-if="picture" style="width: 100px; height: 100px" />
                  <div v-else>
                    <a-icon :type="pictureLoading ? 'loading' : 'plus'" />
                  </div>
                </a-upload>
              </div>
            </a-form-item>
            <!--        礼物特效    -->
            <a-form-item has-feedback label="礼物特效" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-upload
                name="animation"
                :show-upload-list="false"
                :customRequest="uploadAnimation"
                :preview-file="previewFile"
                v-decorator="['animationUrl', { rules: [{ required: false, message: '请上传！' }] }]"
              >
                <a-button> <a-icon type="upload" /> 上传 </a-button>

                <div v-if="animationUrl" style="margin-top: 5px; word-break: break-all">
                  文件地址：{{ animationUrl }}
                </div>
              </a-upload>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { giftAdd } from '@/api/modular/mallLiving/check/gift'
import {uploadFile} from "@/api/modular/mallLiving/uploadFile";

export default {
  data() {
    return {
      loading: false,
      imageUrl: '',
      picture: '',
      animationUrl: '',
      allList: [],
      animationList: [],
      cos: null,
      pictureLoading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelCol_1: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol_1: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
      parentList: [], //父级列表
      sort: '',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
    }
  },

  methods: {
    // 初始化方法
    add() {
      //下面是正常的初始化方法
      this.visible = true
      this.picture = ''
    },
    previewFile(file) {
      console.log('Your upload file:', file)
    },
    handleSubmit() {
      this.confirmLoading = true
      if (!this.animationUrl && this.animationList.length > 0) {
        this.confirmLoading = false
        return this.$message.info('特效文件上传中，请稍后')
      }
      this.form.validateFields((errors, values) => {
        if (!errors) {
          values.logo = this.picture
          delete values.animationUrl
          values.animationUrl = this.animationUrl
          delete values.picture

          giftAdd(values).then((res) => {
            if (res.success) {
              this.$message.success('添加礼物成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.animationList = []
      this.form.resetFields() //重置表单
    },
    //自定义图片上传实现
    uploadImg(filedata) {
      this.pictureLoading = true
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          const percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          const list = res.res.requestUrls[0].split('?');
          if (list.length > 0) {
            this.picture = list[0]
            this.pictureLoading = false
          }
        }
      })
    },
    uploadAnimation(filedata) {
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          const percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          const list = res.res.requestUrls[0].split('?');
          if (list.length > 0) {
            this.animationUrl = list[0]
          }
        }
      })
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
